import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Content from "../components/content"
import NewsPreview from "../components/utility/news-preview"

const IndexPage = ({data}) => {
  const page = data.strapiHomepage
  const content = page.content
  return (
  <>
    <Seo title={page.title} />
    {/* <Prose>
      <h1 className="mb-14">{page.title}</h1>
    </Prose> */}
    <NewsPreview />
    <Content content={content}></Content>
  </>
)}

export default IndexPage


export const indexPageQuery = graphql`
  query indexPage {
    strapiHomepage {
      id
      title
      content {
        ... on STRAPI__COMPONENT_PAGE_ALERT {
          ...pageAlert
        }
        ... on STRAPI__COMPONENT_PAGE_CARDS {
          ...pageCards
        }
        ... on STRAPI__COMPONENT_PAGE_GALLERY {
          ...pageGallery
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE {
          ...pageImage
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE_TEXT {
          ...pageImageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT {
          ...pageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_IMAGE {
          ...pageTextImage
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_TEXT {
          ...pageTextText
        }
        ... on STRAPI__COMPONENT_PAGE_WIDGET {
          ...pageWidget
        }
      }
    }
  }
`
